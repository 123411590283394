import { useEffect, useState } from 'react';
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import Button from '@tidsbanken/components/buttons/Button';
import { AxiosError } from 'axios';
import QRCode from 'qrcode.react';
import logo from './Logo.png';
import './Print.less';
import stempleboks from '~src/Helpers/apiHelpers';
import { Anlegg } from '~src/Helpers/Types';

const locationCode = new URLSearchParams(window.location.search).get('print');

const getAnleggName = async (): Promise<string | undefined | void> => {
    return await stempleboks
        .post<Anlegg>('/anlegg', {
            id: locationCode,
        })
        .then((res) => res.data.Anlegg.Navn)
        .catch((e: AxiosError) => {
            console.error(e.message);
        });
};

type Props = {
    locationCode: string;
};

const Print = ({ locationCode }: Props): JSX.Element => {
    const [anleggNavn, setAnleggNavn] = useState<string>('');

    useEffect(() => {
        getAnleggName().then((res) => {
            if (res) {
                setAnleggNavn(res);
            }
        });
    }, []);

    return (
        <div className="print">
            <Button
                className="no-print"
                onClick={() => {
                    window.print();
                }}
                label="Print"
                kind="info"
                icon={faPrint}
                leftAlignIcon={true}
            ></Button>

            <h3>Welcome to</h3>
            <h2 style={{ marginTop: '0.2rem', fontWeight: 'bold' }}>{anleggNavn}</h2>

            <h2 style={{ marginTop: '6rem' }}>To sign in / out</h2>
            <h2 style={{ marginTop: '0' }}>Scan QR-code</h2>

            <QRCode
                renderAs="svg"
                level="M"
                size={200}
                value={`https://min.tidsbanken.net/reg2build/bygg.asp?byggkode=${locationCode}`}
                imageSettings={{ src: logo, height: 24, width: 24, excavate: true }}
                style={{ marginTop: '3rem', marginBottom: '3rem' }}
            />

            <h3>Remember to sign out when leaving</h3>

            <h3 style={{ marginTop: '4rem' }}>Web</h3>
            <a style={{fontSize: '24px'}}>https://reg2.build/</a>

            <h3 style={{ marginTop: '4rem' }}>Location Code:</h3>
            <h1 style={{ marginTop: '0', fontWeight: 'bold' }}>{locationCode}</h1>
        </div>
    );
};

export default Print;
